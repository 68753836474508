$spacing: 0.5rem;
$spacing3x: 3 * $spacing;
$spacing4x: 4 * $spacing;
$spacing6x: 6 * $spacing;
$spacing8x: 8 * $spacing;

$padTop: 14px;
$padRight: $spacing8x;
$padBottom: 15px;
$padLeft: $spacing6x;

@mixin outline-shared {
  background-color: transparent;
  padding: $padTop - 1px calc(#{$padRight} - 1px) $padBottom - 1px
    calc(#{$padLeft} - 1px);
  border: 1px solid transparent;
  &:hover,
  &:focus {
    padding: $padTop - 2px calc(#{$padRight} - 2px) $padBottom - 2px
      calc(#{$padLeft} - 2px);
    border-width: 2px;
  }
  // version with no chevron
  &.no-icon {
    padding: $padTop - 1px calc(#{$padLeft} - 1px) $padBottom - 1px
      calc(#{$padLeft} - 1px);
    &:hover,
    &:focus {
      padding: $padTop - 2px calc(#{$padLeft} - 2px) $padBottom - 2px
        calc(#{$padLeft} - 2px);
    }
  }
}

@mixin link-style-shared {
  padding: 4px 10px 5px 15px;
  background: transparent;
  border: 0;
}

.button {
  border: 0 none;
  display: inline-block;

  vertical-align: middle;
  font-size: 1.125rem;
  line-height: 1;
  text-align: left;
  cursor: pointer;
  text-decoration: none;

  &--primary,
  &--secondary,
  &--tertiary {
    font-family: $family-primary;
    font-weight: bold;
    border-radius: $radius-rounded;
    color: #fff;
    padding: $padTop $padRight $padBottom $padLeft;
    white-space: nowrap;
    transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    display: block;
    width: 100%;
    text-align: center;

    > span {
      min-width: 9.375rem; /* 150 / 16 */
      display: inline-block;
    }

    @include desktop {
      width: auto;
    }
    > .drxi {
      font-size: 1.25em;
      position: relative;
      display: inline-block;
    }
  }

  &[disabled] {
    // background: rgb(255, 203, 6);
    // color: rgb(63, 20, 130);
    opacity: 0.5;
    mix-blend-mode: luminosity;
  }
  &.is-submitting {
    opacity: 1;
    mix-blend-mode: normal;
  }
  &.is-success {
    background: rgb(105, 190, 40);
    border-color: rgb(105, 190, 40);
    color: $white;
    opacity: 1;
    mix-blend-mode: normal;
  }
  &.no-icon {
    padding: $padTop $padLeft $padBottom $padLeft;
    &.text-centered {
      text-align: center;
      min-width: 150px;
    }
  }

  &.has-icon-left {
    padding: $padTop $padLeft $padBottom $padRight;
    > .drxi {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $spacing4x;
    }
    &.has-shift-transition {
      > .drxi {
        transition: left 0.15s ease-in-out;
      }
      &:hover,
      &:focus {
        &:not([disabled]) {
          > .drxi {
            left: $spacing3x;
          }
        }
      }
    }
  }
  &.has-icon-right {
    > .drxi {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $spacing4x;
    }
    &.has-shift-transition {
      > .drxi {
        transition: right 0.15s ease-in-out;
      }
      &:hover,
      &:focus {
        &:not([disabled]) {
          > .drxi {
            left: auto;
            right: $spacing3x;
          }
        }
      }
    }
  }
  &--primary {
    height: auto;
    &--purple {
      background-color: $eggplant;
      border-color: $eggplant;
      color: #fff;
      &:hover,
      &:focus {
        background-color: $light-eggplant;
      }
    }
    &--white {
      background-color: #fff;
      border-color: #fff;
      color: $eggplant;
      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.85);
      }
    }
    &--yellow {
      background-color: $yellow;
      border-color: $yellow;
      color: $eggplant;
      &:hover,
      &:focus {
        background-color: $yellow-hover;
      }
      &[disabled] {
        background-color: $yellow;
        border-color: $yellow;
      }
    }
  }
  &--secondary {
    height: auto;
    &--purple {
      @include outline-shared;
      border-color: $eggplant;
      color: $eggplant;
    }
    &--white {
      @include outline-shared;
      border-color: #fff;
      color: #fff;
    }
  }
  &--tertiary {
    &--purple {
      @include link-style-shared;
      border-color: $eggplant;
      color: $eggplant;
    }
    &--white {
      @include link-style-shared;
      border-color: #fff;
      color: #fff;
    }
  }
}
