//Fonts Files Path
$fontsPath: './../fonts/';
$icon-font-family: 'drx' !default;

@font-face {
  font-family: '#{$icon-font-family}';
  src: url('#{$fontsPath}/icons/#{$icon-font-family}.ttf?dawt4a')
      format('truetype'),
    url('#{$fontsPath}/icons/#{$icon-font-family}.woff?dawt4a')
      format('woff'),
    url('#{$fontsPath}/icons/#{$icon-font-family}.svg?dawt4a##{$icon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url('#{$fontsPath}CircularXXWeb-Medium.woff2') format('woff2'),
    url('#{$fontsPath}CircularXXWeb-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Circular-Regular';
  src: url('#{$fontsPath}CircularXXWeb-Regular.woff2') format('woff2'),
    url('#{$fontsPath}CircularXXWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
