@import './fonts.scss';

// Colors

$black: #000000;
$black-bis: $black;
$black-ter: $black;

$grey-darker: #444444;
$grey-dark: #56565a;
$grey: #c6c6c6;
$grey-light: #efefef;
$grey-lighter: $grey-light;

$white: #ffffff;
$white-ter: $white;
$white-bis: $white;

$orange: #fa9370;
$yellow: #f1be48;
$green: #92aca0;
$turquoise: #00d1b2;
$cyan: #209cee;
$blue: #384967;
$purple: #5c4b64;
$red: #eb0606;

// Typography

$family-sans-serif: 'Circular-Regular', 'Calibri', sans-serif;
$family-monospace: monospace;
$render-mode: optimizeLegibility;

$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px;
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px;
// 960px container + 4rem
$desktop: 960px + (2 * $gap);
// 1152px container + 4rem
$widescreen: 1180px + (2 * $gap);
$widescreen-enabled: true;
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap);
$fullhd-enabled: false;

// Miscellaneous

$easing: ease-out;
$radius-small: 0px;
$radius: 2px;
$radius-large: 4px;
$radius-rounded: 290486px;
$speed: 86ms;

// Flags

$variable-columns: true;
