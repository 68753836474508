@import './variables';
@import '~bulma/sass/utilities/functions.sass';

// legacy
$eggplant: #3f1482;
$light-eggplant: #682bc6;
$light-eggplant-25: #d9caf1;

// support
$charcoal: #404042;
$cool-grey: #d6d6d6;
$warm-grey: #ebe7dc;
$coral: $orange;
$navy: $blue;
$sand: #fcc89b;

// brand
$brand-main: $yellow;
$brand-navy: $navy;

$primary: $yellow;
$yellow-hover: #f4b013;
$primary-hover: $yellow-hover;

$error: $red;
$info: $cyan;
$success: #26a800;
$warning: $yellow;
$danger: $red;

$light: $white-ter;
$dark: $grey-darker;

// Invert colors

$orange-invert: findColorInvert($orange);
$yellow-invert: findColorInvert($yellow);
$green-invert: findColorInvert($green);
$turquoise-invert: findColorInvert($turquoise);
$cyan-invert: findColorInvert($cyan);
$blue-invert: findColorInvert($blue);
$purple-invert: findColorInvert($purple);
$red-invert: findColorInvert($red);

$primary-invert: $turquoise-invert;
$info-invert: $cyan-invert;
$success-invert: $green-invert;
$warning-invert: $yellow-invert;
$danger-invert: $red-invert;
$light-invert: $dark;
$dark-invert: $light;

// column gap
$column-gap: 1.125em;

// General colors

$background: $white-ter;
$border: $grey-lighter;
$border-hover: $grey-light;

// Text colors

$text: $brand-navy;
$text-invert: findColorInvert($text);
$text-light: $grey;
$text-strong: $black;

// Code colors

$code: $red;
$code-background: $background;

$pre: $text;
$pre-background: $background;

// Link colors

$link: $eggplant;
$link-invert: $text-invert;
$link-visited: $text;

$link-hover: $eggplant;
$link-hover-border: $eggplant;

$link-focus: $grey-darker;
$link-focus-border: $grey-darker;

$link-active: $grey-darker;
$link-active-border: $grey-dark;

// Typography

$family-primary: $family-sans-serif;
$family-secondary: 'Circular-Medium', $family-sans-serif;
$family-code: $family-monospace;

$size-small: $size-7;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;

// Lists and maps
$custom-colors: null;
$custom-shades: null;

$colors: mergeColorMaps(
  (
    'white': (
      $white,
      $black,
    ),
    'black': (
      $black,
      $white,
    ),
    'light': (
      $light,
      $light-invert,
    ),
    'dark': (
      $dark,
      $dark-invert,
    ),
    'primary': (
      $primary,
      $primary-invert,
    ),
    'link': (
      $link,
      $link-invert,
    ),
    'info': (
      $info,
      $info-invert,
    ),
    'success': (
      $success,
      $success-invert,
    ),
    'warning': (
      $warning,
      $warning-invert,
    ),
    'danger': (
      $danger,
      $danger-invert,
    ),
  ),
  $custom-colors
);
$shades: mergeColorMaps(
  (
    'black-bis': $black-bis,
    'black-ter': $black-ter,
    'grey-darker': $grey-darker,
    'grey-dark': $grey-dark,
    'grey': $grey,
    'grey-light': $grey-light,
    'grey-lighter': $grey-lighter,
    'white-ter': $white-ter,
    'white-bis': $white-bis,
  ),
  $custom-shades
);

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

// navbar/#variables
// -----------------------------------------------------
$navbar-breakpoint: $tablet;
$navbar-height: 2.8125rem; /* 45px */
$navbar-background-color: #fff;
$navbar-item-hover-color: $link;
$navbar-item-hover-background-color: rgba(204, 204, 204, 0.2);
