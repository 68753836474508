$navbar-border: 5px;

.navbar {
  border-top: $navbar-border solid $yellow;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.navbar-brand {
  width: 100%;
}
.navbar-item {
  width: 90px;
  margin: 0 auto;
  padding: 0;
  @include desktop {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
  }
  img {
    position: relative;
    right: -10px;
    display: block;
    width: 100%;
    max-height: none;
  }
}
