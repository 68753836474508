@charset "utf-8";

// Project Variables
@import './variables-custom';

// Project common
@import './icons';

// Buttons
// -----------------------------------------------------
$button-text-color: $link;
$button-text-hover-background-color: transparent;
$button-text-hover-color: $link-hover;

// navbar/#variables
// -----------------------------------------------------
$navbar-breakpoint: $desktop;
$navbar-height: 3.25rem; /* 52px */
$navbar-background-color: #fff;
$navbar-item-hover-color: $link;
$navbar-item-hover-background-color: rgba(204, 204, 204, 0.2);

// Project framework
// Utilities
@import 'bulma/sass/utilities/_all.sass';

// Base
@import 'bulma/sass/base/_all.sass';

// Components
@import 'bulma/sass/components/navbar.sass';

// Elements
@import 'bulma/sass/elements/button.sass';
@import 'bulma/sass/elements/container.sass';
@import 'bulma/sass/elements/icon.sass';
@import 'bulma/sass/elements/image.sass';
@import 'bulma/sass/elements/other.sass';

// Project components
@import './buttons';
@import './../../components/NavbarBasic/NavbarBasic';

html,
body {
  // background shown on the sidebar
  background: #fff;
}
#main {
  &:focus {
    outline: none;
  }
}

//
// Sticky Footer
// --------------------------------------------------
html {
  min-height: 100%;
}
html,
body,
#root,
.app {
  height: 100%;
  @include mobile {
    overflow-y: visible;
  }
}
.app {
  background: #fff;
  width: 100%;
}
.wrapper {
  // Often padding, margin, etc. don’t behave as expected.
  // This is easy enough to work around by adding a <div>
  // or something inside the element and styling that.
  height: 100%;
  #main {
    min-height: calc(100vh - calc(#{$navbar-height} + 5px));
    // children of parents with min-height can't inherit the height property
    position: relative; // add position:relative
  }
}

// Error handling
// --------------------------------------------
.error-message {
  &--authorizer {
    min-height: 100%; // allows content to grow larger than the screen height if needed
    position: absolute; // add position:absolute
    padding: 2rem 1rem 2.5rem;
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    @include desktop {
      position: static;
      max-width: 720px;
      min-height: 600px;
      margin: 0 auto;
      padding-top: 5rem;
    }
  }

  &__content {
    padding-bottom: 2rem;
    flex-grow: 1;
  }
  &__button-control {
    // margins and spacing with multiple
    display: flex;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
    .button {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      @include desktop {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2.5rem;
        margin-right: 2.5rem;
      }
      &:first-child {
        margin-top: 0;
        @include desktop {
          margin-left: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
        @include desktop {
          margin-right: 0;
        }
      }
    }
  }

  &__image {
    max-width: 120px;
    margin-bottom: 1rem;
    img {
      display: block;
    }
    @include desktop {
      margin-bottom: 2rem;
    }
  }
  &__title {
    color: $brand-navy;
    font: 500 2.125rem/1.176470588235294 $family-secondary; /* 34/16 */ /* 40/34 */
    margin-bottom: 0.5rem;
    @include desktop {
      margin-bottom: 1rem;
    }
  }
  &__text {
    color: $brand-navy;
    font: 400 1.125rem/1.555555555555556 $family-primary; /* 18/16 */ /* 28/18 */
    a {
      font-weight: 700;
    }
    &--code {
      margin-top: 2rem;
    }
  }
}

// Animations and keyframes
// --------------------------------------------
@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

// Spinner (page/section spinner)
// --------------------------------------------
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100vh;
  height: 100%;
  background: $grey-light;
  .spinner__content {
    text-align: center;
    .spinner__text {
      color: rgb(51, 74, 106);
      font: 400 1em/1.5 'Circular-Medium';
      text-align: center;
    }
    .fader {
      transition: opacity 2.5s linear;
      &.fadedOut {
        opacity: 0;
      }
    }
  }
  .spinner__loading {
    margin: 0 auto;
    display: block;
    width: 46px;
    height: 46px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -23px 0 0 -23px;
    &:after {
      @include animation('lds-dual-ring 1.2s linear infinite');
      content: ' ';
      display: block;
      width: 46px;
      height: 46px;
      margin: 1px;
      border-radius: 50%;
      border: 5px solid $grey;
      border-color: $grey transparent $grey transparent;
    }
  }
}

@include keyframes(lds-dual-ring) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
