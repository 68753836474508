$drxi--suggestion: '\e92d';
$drxi--show: '\e929';
$drxi--hide: '\e92a';
$drxi--arrow: '\e901';
$drxi--chevron: '\e903';
$drxi--check: '\e904';
$drxi--close: '\e90b';
$drxi--add: '\e92b';
$drxi--delete: '\e92c';
$drxi--contact: '\e90d';
$drxi--document: '\e90e';
$drxi--download: '\e914';
$drxi--email: '\e918';
$drxi--error: '\e919';
$drxi--menu: '\e921';
$drxi--hours: '\e922';
$drxi--location: '\e923';
$drxi--search: '\e924';
$drxi--user: '\e925';
$drxi--website: '\e926';
$drxi--ext-link: '\e90a';
$drxi--help: '\e90c';
$drxi--device: '\e90f';
$drxi--home: '\e910';
$drxi--edit: '\e902';
$drxi--lock: '\e905';
$drxi--enquiry: '\e900';
$drxi--user-guides: '\e91f';
$drxi--note: '\e911';
$drxi--sound: '\e912';
$drxi--usage: '\e913';
$drxi--power: '\e916';
$drxi--apps: '\e915';
$drxi--accessory: '\e917';
$drxi--device-repair: '\e91a';
$drxi--chat: '\e906';
$drxi--letter: '\e909';
$drxi--dislike: '\e907';
$drxi--like: '\e908';
$drxi--views: '\e920';
$drxi--facebook: '\e91b';
$drxi--twitter: '\e91c';
$drxi--instagram: '\e91d';
$drxi--youtube: '\e91e';
$drxi--linkedin: '\e927';
$drxi--google-plus: '\e928';

.drxi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.drxi--rotate-90 {
  transform: rotate(90deg);
}
.drxi--rotate-180 {
  transform: rotate(180deg);
}
.drxi--rotate-270 {
  transform: rotate(270deg);
}
.drxi--flip-h {
  transform: scaleX(-1);
}
.drxi--flip-v {
  transform: scaleY(-1);
}
.drxi--flip-both {
  transform: scale(-1);
}

.drxi--xs {
  font-size: 0.75em;
}

.drxi--sm {
  font-size: 0.875em;
}

.drxi--md {
  font-size: 1.125em;
}

.drxi--lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.drxi--xl {
  font-size: 1.5em;
}

.drxi--1x {
  font-size: 1em;
}

.drxi--2x {
  font-size: 2em;
}

.drxi--3x {
  font-size: 3em;
}

.drxi--4x {
  font-size: 4em;
}

.drxi--5x {
  font-size: 5em;
}

.drxi--6x {
  font-size: 6em;
}

.drxi--7x {
  font-size: 7em;
}

.drxi--8x {
  font-size: 8em;
}

.drxi--9x {
  font-size: 9em;
}

.drxi--10x {
  font-size: 10em;
}

.drxi--fw {
  text-align: center;
  width: 1.25em;
}
.drxi--suggestion {
  &:before {
    content: $drxi--suggestion;
  }
}
.drxi--show {
  &:before {
    content: $drxi--show;
  }
}
.drxi--hide {
  &:before {
    content: $drxi--hide;
  }
}

.drxi--arrow {
  &:before {
    content: $drxi--arrow;
  }
}
.drxi--chevron {
  &:before {
    content: $drxi--chevron;
  }
}
.drxi--chevron--left {
  &:before {
    content: $drxi--chevron;
    display: block;
    transform: rotate(180deg);
  }
}
.drxi--chevron--right {
  &:before {
    content: $drxi--chevron;
    display: block;
    transform: rotate(0);
  }
}
.drxi--chevron--up {
  &:before {
    content: $drxi--chevron;
    display: block;
    transform: rotate(-90deg);
  }
}
.drxi--chevron--down {
  &:before {
    content: $drxi--chevron;
    display: block;
    transform: rotate(90deg);
  }
}
.drxi--check {
  &:before {
    content: $drxi--check;
  }
}
.drxi--close {
  &:before {
    content: $drxi--close;
  }
}
.drxi--add {
  &:before {
    content: $drxi--add;
  }
}
.drxi--delete {
  &:before {
    content: $drxi--delete;
  }
}
.drxi--contact {
  &:before {
    content: $drxi--contact;
  }
}
.drxi--document {
  &:before {
    content: $drxi--document;
  }
}
.drxi--download {
  &:before {
    content: $drxi--download;
  }
}
.drxi--email {
  &:before {
    content: $drxi--email;
  }
}
.drxi--error {
  &:before {
    content: $drxi--error;
  }
}
.drxi--menu {
  &:before {
    content: $drxi--menu;
  }
}
.drxi--hours {
  &:before {
    content: $drxi--hours;
  }
}
.drxi--location {
  &:before {
    content: $drxi--location;
  }
}
.drxi--search {
  &:before {
    content: $drxi--search;
  }
}
.drxi--user {
  &:before {
    content: $drxi--user;
  }
}
.drxi--website {
  &:before {
    content: $drxi--website;
  }
}
.drxi--external,
.drxi--ext-link {
  &:before {
    content: $drxi--ext-link;
  }
}
.drxi--help {
  &:before {
    content: $drxi--help;
  }
}
.drxi--device {
  &:before {
    content: $drxi--device;
  }
}
.drxi--home {
  &:before {
    content: $drxi--home;
  }
}
.drxi--edit {
  &:before {
    content: $drxi--edit;
  }
}
.drxi--lock {
  &:before {
    content: $drxi--lock;
  }
}
.drxi--enquiry {
  &:before {
    content: $drxi--enquiry;
  }
}
.drxi--user-guides {
  &:before {
    content: $drxi--user-guides;
  }
}
.drxi--note {
  &:before {
    content: $drxi--note;
  }
}
.drxi--sound {
  &:before {
    content: $drxi--sound;
  }
}
.drxi--usage {
  &:before {
    content: $drxi--usage;
  }
}
.drxi--power {
  &:before {
    content: $drxi--power;
  }
}
.drxi--apps {
  &:before {
    content: $drxi--apps;
  }
}
.drxi--accessory {
  &:before {
    content: $drxi--accessory;
  }
}
.drxi--device-repair {
  &:before {
    content: $drxi--device-repair;
  }
}
.drxi--chat {
  &:before {
    content: $drxi--chat;
  }
}
.drxi--letter {
  &:before {
    content: $drxi--letter;
  }
}
.drxi--dislike {
  &:before {
    content: $drxi--dislike;
  }
}
.drxi--like {
  &:before {
    content: $drxi--like;
  }
}
.drxi--views {
  &:before {
    content: $drxi--views;
  }
}
.drxi--facebook {
  &:before {
    content: $drxi--facebook;
  }
}
.drxi--twitter {
  &:before {
    content: $drxi--twitter;
  }
}
.drxi--instagram {
  &:before {
    content: $drxi--instagram;
  }
}
.drxi--youtube {
  &:before {
    content: $drxi--youtube;
  }
}
.drxi--linkedin {
  &:before {
    content: $drxi--linkedin;
  }
}
.drxi--google-plus {
  &:before {
    content: $drxi--google-plus;
  }
}
